import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine.js";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusy,
  setIdle,
  bindGames,
  setGameLobbyModal,
  setLoginModal,
} from "../../redux/AppAction.js";
import {
  map,
  find,
  filter,
  includes,
} from "lodash";
import GameLobbyModal from "components/template_1/GameLobbyModal";
import FallBackImage from "components/template_1/FallBackImage";
import LoadingGames from "components/template_1/LoadingGames";
import SwiperCore, { Autoplay } from 'swiper';
import {
  stringIsNullOrEmpty,
} from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";


// ProviderList component
const ProviderList = ({ updateMasterProductId, updateSelectedGameIsSeamless, updateSelectedGameName, updateProductGameId,
  updatePopBannerImage, updateTitle, updateCategoryTitle, UpdateIsSupportIframe, updateProviderName , pageName}) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  // Swiper Plugins
  SwiperCore.use([Autoplay]);
  var { heartBeat } = useSelector((state) => state.authState);
  const memberData = useSelector((state) => state.appState.member);

  const [gameCategoryActive, setGameCategoryActive] = useState({
    id: -1,
    title: "All",
  });
  const [gameCategories, setGameCategories] = useState([]);
  const [games, setGames] = useState(
    useSelector((state) => state.appState.games)
  );
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [isApp, setIsApp] = useState(false);
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [productCommRate, setProductCommRate] = useState([]);
  const [appUrl, setAppUrl] = useState("");
  const [clubFee, setClubFee] = useState(0);
  const latestBets = useSelector((state) => state.appState.latestBets);
  const [isLoading, setIsLoading] = useState(false);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [alertIcon, setAlertIcon] = useState();
  const [teamBanner, setTeamBanner] = useState();
  const [coinIcon, setCoinIcon] = useState();
  const [logo, setLogo] = useState();
  const [noDataBg, setNoDataBg] = useState();
  const [referralBannerMobile, setReferralBannerMobile] = useState("");
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const [isSupportIframeState, setIsSupportIframeState] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [providerName, setProviderName] = useState("");
  const [referralBanner, setReferralBanner] = useState("");
  const [initFlag, setInitFlag] = useState(false);
  const [iframeAlert, setIsIframeAlert] = useState(false);

  const [selectedGameCategoryGames, setSelectedGameCategoryGames] = useState([]);
  const [isNewWindow, setIsNewWindow] = useState(false);
  
  const availableProductCategories = useSelector((state) => state.appState.availableProductCategories);

  useEffect(() => {
    getCompanyIcon();
    init()
  }, []);

  useEffect(() => {
    if (gameCategoryActive.title === "All") {
      setSelectedGameCategoryGames(games);
    }
    else {
      var selectedCategoryGameList = games.filter(game => game["categoryTitle"] === gameCategoryActive.title);
      setSelectedGameCategoryGames(selectedCategoryGameList);
    }

  }, [gameCategoryActive]);

  useEffect(() => {
    if (games.length) {
      setSelectedGameCategoryGames(games);
    }
  }, [games]);

  async function getCompanyIcon() {
    _dispatch(setBusy());
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage||alert.defaultImage);
    }

    const gameCategories = [];
    const pathnamesToFilter = ["/game-list", "/sports", "/casino", "/slots", "/keno", "/fish", "/e-sport", "/arcade"];

    const gameList = filter(companyIcons, item => includes(pathnamesToFilter, item.pathname));
    if (gameList) {
      gameList.forEach(function (x) {
        gameCategories.push({
          id: x.iconName == "All" ? -1 : x.id,
          title: x.iconName,
          image: x.iconImage||x.defaultImage,
        });
      });
      setGameCategories(gameCategories);
    }

    const teamBanner = find(companyIcons, { iconCategory: "team_banner" });
    if (teamBanner) {
      setTeamBanner(teamBanner.iconImage||teamBanner.defaultImage);
    }

    const coinIcon = find(companyIcons, { iconCategory: "coin_icon" });
    if (coinIcon) {
      setCoinIcon(coinIcon.iconImage||coinIcon.defaultImage);
    }

    const logo = find(companyIcons, { iconCategory: "logo" });
    if (logo) {
      setLogo(logo.iconImage||logo.defaultImage);
    }

    const noDataBg = find(companyIcons, { iconCategory: "no_data_background" });
    if (noDataBg) {
      setNoDataBg(noDataBg.iconImage||noDataBg.defaultImage);
    }

    const tempReferralBanner = find(companyIcons, { iconCategory: "referral_banner" });
    if (tempReferralBanner) {
      setReferralBanner(tempReferralBanner.iconImage||tempReferralBanner.defaultImage);
    }

    const tempReferralBannerMobile = find(companyIcons, { iconCategory: "referral_banner_mobile" });
    if (tempReferralBannerMobile) {
      setReferralBannerMobile(tempReferralBannerMobile.iconImage||tempReferralBannerMobile.defaultImage);
    }

    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage||defaultGameIconTemp[0]?.defaultImage);
    }

    _dispatch(setIdle());
  }

  async function init() {
    
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_GROUP_GAME_DASHBOARD
    );

    setInitFlag(true);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setGames(responseJson[ApiKey._API_DATA_KEY]);
      _dispatch(bindGames(responseJson[ApiKey._API_DATA_KEY]));
      /// preload
      responseJson[ApiKey._API_DATA_KEY].map((game, index) => {
        let image = new Image();
        image.src = game["image"];

        window[game["image"]] = image;
      });
    } else {
      throw responseJson[ApiKey._API_MESSAGE_KEY];
    }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword,
    isSupportIframe,
    categoryTitle,
    providerName,
    popoutBanner
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    setSelectedGameHasOwnLobby(hasOwnLobby);
    setSelectedGameIsSeamless(isSeamless);
    setIsSupportIframeState(isSupportIframe);
    setCategoryTitle(categoryTitle);
    setProviderName(providerName);

    if (pageName == "HOME" && hasOwnLobby !== true) {
      updateMasterProductId(masterProductId);
      updateSelectedGameIsSeamless(isSeamless);
      updateSelectedGameName(gameName);
      updateProductGameId(gameId);
      updatePopBannerImage(popoutBanner);
      updateTitle(t("HOME"))
      updateCategoryTitle(categoryTitle);
      UpdateIsSupportIframe(isSupportIframe);
      updateProviderName(providerName);
    }
    

    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        setProductGameId(gameId);
        _dispatch(setGameLobbyModal(true));
      } else {
        if (isSupportIframe) {
          var breadcrumbData = [{
            pageName: t("HOME"),
            location: _history.location.pathname + _history.location.search,
            state: _history.location.state
          }];

          var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
          _history.push(path, {
            breadcrumbData: breadcrumbData
          });
        } else {
          window.open(
            ApiUrl._API_START +
            "?gameId=" +
            gameId + "&device=" + (window.innerWidth <= 768 ? "m" : "d")
          );
        }

      }
    } else {
      if (isSeamless == false) {
        setIsLoading(true);
        
        setShowPregamePopup(true);
        getProductComRate(gameName);
        setProductGameId(gameId);
        SetToLobbyBtn(true);
        getRolloverProgress(gameId);

        var path = WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"] +
          "&category=" +
          categoryTitle;

        var breadcrumbData = [{
          pageName: t('HOME'),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }, {
          pageName: gameName,
          location: path
        }];

        var state = {
          breadcrumbData: breadcrumbData
        }

        _history.push(path, state);

        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

        }

        setIsLoading(false);
      }
    }
  }

  async function getProductComRate(selectedGameName) {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }

  async function getRolloverProgress(masterProductId) {
    setRolloverBalance("");
    setRolloverTarget("");
    if (masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
          }
        }
      }
    }
  }

  return (
    <>
      {iframeAlert && (
        <div className="sweet-alert-container t3-sweet-alert-container">
          <SweetAlert
            customIcon={(<img
              className="m-auto"
              src={alertIcon}
              height="90px"
              width="90px"
              alt="Custom Icon"
            />)}
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONTINUE")}
            icon
            // onConfirm={() => {
            //   getRestoreAccountBlance();
            // }}
            onCancel={() => {
              setIsIframeAlert(false);
            }}
            custom
            showCancel
            reverseButtons={true}
            cancelBtnBsStyle="danger"
            customButtons={
              <React.Fragment>
                <div className="sweetalert-custom-buttons">
                  <button
                    className={"btn btn-lg btn-primary"}
                    onClick={() => {
                      startGame(
                        productGameId,
                        selectedGameHasOwnLobby,
                        masterProductId,
                        selectedGameName,
                        selectedGameIsSeamless,
                        isApp,
                        appDeepLink,
                        isNewWindow,
                        packageNameKeyword,
                        isSupportIframeState
                      )
                      setIsIframeAlert(false);
                    }}
                  >
                    {t("CONTINUE")}
                  </button>
                  <a
                    onClick={() => (_dispatch(setGameLobbyModal(false)), setIsIframeAlert(false))}
                    className="btn btn-primary btn-lg"
                  >
                    {t("CLOSE")}
                  </a>
                </div>

              </React.Fragment>
            }
          >
            <span className="sweet-alert-custom-text">
              <div dangerouslySetInnerHTML={{ __html: t("IFRAME_WARM_").replace('\n', '<br/>') }} style={{ marginBottom: "20px", fontSize: "14px" }} />
            </span>
          </SweetAlert>
        </div>
      )}
      {games?.length > 0 && (<>
        <div className="t3-game-category-box mt-4 mb-4">
          <div className="t3-game-category-list">
            {gameCategories &&
              gameCategories.length > 0 &&
              map(gameCategories, (item, index) => {
                // CHRIS 20250107 TODO: Instead of using iconSettings to configure sideMenuList, create a separate sidenav menu list configurator, iconSettings only use for showing icon
                // Only apply this filtering criteria if availableProductCategories is not empty
                if(availableProductCategories.length > 0) {
                  // Call gameCategories to get only the available categories - hide categories that are not available
                  let availableProductCategory = availableProductCategories.filter((productCategory) => {
                    return productCategory.productCategory.title.trim() == item.title.trim();
                  });

                  if (availableProductCategory.length == 0 ) {
                    return null;
                  }
                }

                return (
                  <div
                    key={index}
                    className={
                      "t3-game-category-item" +
                      (gameCategoryActive.id == item.id ? " active " : " ") +
                      "t3-game-category-item-" + item.title
                    }
                    onClick={() =>
                      setGameCategoryActive({
                        id: item.id,
                        title: item.title,
                      })
                    }
                  >
                    <div className="first">
                      <img
                        src={item.image}
                        className="img-responsive"
                        alt={item.title}
                        loading="lazy"
                      />
                    </div>
                    <h6>{t(item.title)}</h6>
                  </div>
                );
              })}
          </div>
        </div>
        {selectedGameCategoryGames?.length > 0 && (
          <div className="t3-game-list-container">
            <div className="t3-category-game-list-container">
              {/* <NetflixLoader/>  */}
              {games.length == 0 && <LoadingGames />}
              <div className="t3-game-list-box">
                {selectedGameCategoryGames.map((game, index) => {
                  return (
                    <div
                      key={index}
                      className="t3-game-list-item"
                      onClick={() => {
                        if (heartBeat) {
                          //updateGameDetail()
                          setPopupBannerImage(game["popupBannerImage"]);
                          if (game["gameUm"] !== true) {
                            setIsSupportIframeState(game["isSupportIframe"])

                            if (game["isSupportIframe"]) {
                              startGame(
                                game["id"],
                                game["hasOwnLobby"],
                                game["masterProductId"],
                                game["gameName"],
                                game["masterProductIsSeamless"],
                                game["isApp"],
                                game["appUrl"],
                                game["appDeepLink"],
                                game["isNewWindow"],
                                game["packageNameKeyword"],
                                game["isSupportIframe"],
                                game["categoryTitle"],
                                game["gameName"],
                                game["popupBannerImage"]
                              );
                            }
                            else {
                              setSelectedGameName(game["gameName"]);
                              setMasterProductId(game["masterProductId"]);
                              setIsApp(game["isApp"]);
                              setAppDeepLink(game["appDeepLink"]);
                              setPackageNameKeyword(game["packageNameKeyword"]);
                              setSelectedGameHasOwnLobby(game["hasOwnLobby"]);
                              setSelectedGameIsSeamless(game["isSeamless"]);
                              setIsSupportIframeState(game["isSupportIframe"]);

                              if (isApp && !stringIsNullOrEmpty(appUrl)) {
                                setAppUrl(game["appUrl"]);
                              }
                              setProductGameId(game["id"]);
                              setIsIframeAlert(true);
                            }
                          }
                          /* if (walletsDetails.isValidForPromotion) {
                              _dispatch(
                              showMessage({
                                  custom: true,
                                  customIcon: <img className="m-auto" src={alertIcon} height="120px" width="120px" alt="Custom Icon" />,
                                  content: t("PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"),
                                  showCancel: true,
                                  onConfirm: () => {
                                  setTransactionValidForPromo();
                                  startGame(
                                      game["id"],
                                      game["hasOwnLobby"],
                                      game["masterProductId"],
                                      game["gameName"],
                                      game["masterProductIsSeamless"],
                                      game["isApp"],
                                      game["appUrl"],
                                      game["appDeepLink"],
                                      game["isNewWindow"],
                                      game["packageNameKeyword"]
                                  );
                                  }
                              })
                              );
                          } else {
                              startGame(
                              game["id"],
                              game["hasOwnLobby"],
                              game["masterProductId"],
                              game["gameName"],
                              game["masterProductIsSeamless"],
                              game["isApp"],
                              game["appUrl"],
                              game["appDeepLink"],
                              game["isNewWindow"],
                              game["packageNameKeyword"]
                              );
                          } */
                        } else {
                          _dispatch(setLoginModal(true));
                        }
                      }}
                    >
                      <div className="t3-game-list-image-box">
                        <div className="image">
                          <div className="first">
                            <FallBackImage
                              src={game["image"]}
                              alt={game["gameName"]}
                              fallbackSrc={defaultGameIcon}
                            />
                            {game["gameUm"] == true && (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className="v2-um-tag game-category-label"
                                ></div>
                                <div

                                  className="v2-um-tag-icon"
                                >
                                  <svg className="icon-repair" width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1031_6568)">
                                      <path d="M148.878 18.7427C148.334 16.9377 146.058 16.3699 144.73 17.6977L136.061 26.3672H123.633V13.9389L132.303 5.26523C133.637 3.93047 133.058 1.6626 131.267 1.11797C128.813 0.372363 126.267 0.0541992 123.658 0.0541992C116.59 0.0541992 109.964 2.73779 104.99 7.72471C98.896 13.8144 96.1667 22.522 97.6772 31.0233L98.0806 33.2763L33.276 98.0807C30.6583 97.6128 29.0818 97.2694 26.4009 97.2694C19.4399 97.2694 12.6293 100.085 7.7197 104.99C0.790989 111.923 -1.65091 122.075 1.11648 131.257C1.66199 133.066 3.93367 133.633 5.26433 132.302L13.9344 123.633H26.4258V136.066L17.6953 144.737C16.362 146.069 16.9371 148.341 18.7333 148.886C21.1816 149.626 23.7243 150 26.3282 150C42.7409 150 55.2035 135.192 52.3221 118.976L51.9187 116.723L116.719 51.919C119.293 52.379 120.936 52.7332 123.594 52.7259C130.568 52.7259 137.374 49.9148 142.275 45.0097C149.209 38.0798 151.647 27.9305 148.878 18.7427Z" />
                                      <path d="M143.576 112.489L112.755 81.9729C110.699 79.9204 108.143 78.3135 105.343 77.1926L76.9005 105.635C78.0211 108.436 79.6295 110.994 81.6841 113.052L112.501 143.564C121.066 152.13 134.97 152.161 143.576 143.564C152.142 134.994 152.142 121.055 143.576 112.489ZM131.049 131.037C129.332 132.754 126.551 132.754 124.835 131.037L103.241 109.748C101.524 108.031 101.524 105.25 103.241 103.534C104.957 101.817 107.738 101.817 109.455 103.534L131.049 124.823C132.765 126.54 132.765 129.32 131.049 131.037Z" />
                                      <path d="M37.5822 25.1538L37.5757 25.1558L41.539 21.1925C42.6877 20.0426 42.4463 18.1251 41.0583 17.2925L14.2299 0.62641C12.5011 -0.410992 10.2878 -0.138531 8.86246 1.28735L1.28746 8.86235C-0.139008 10.2888 -0.410883 12.5031 0.627692 14.2322L17.3052 41.0476C18.1408 42.4374 20.0632 42.6692 21.2046 41.5266L25.1518 37.58L53.2507 65.6789L65.6791 53.2506L37.5822 25.1538Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1031_6568">
                                        <rect width="150" height="150" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                              </>
                            )}
                          </div>
                          {game["gameUm"] == false && (
                            <div className="second">
                              <svg
                                className="play-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 63 63"
                              >
                                <defs></defs>
                                <g fill="none" fillRule="evenodd">
                                  <circle
                                    cx="31.3"
                                    cy="31.3"
                                    r="30.3"
                                    fill="currentColor"
                                    fillOpacity=".2"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  ></circle>
                                  <path
                                    fill="currentColor"
                                    d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="t3-game-list-text-box">
                        <h6>{game["gameName"]}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>)}

      {pageName != "HOME" &&
        <GameLobbyModal
          masterProductId={masterProductId}
          selectedGameIsSeamless={selectedGameIsSeamless}
          selectedGameName={selectedGameName}
          productGameId={productGameId}
          popupBannerImage={popupBannerImage}
          title={t("HOME")}
          category={categoryTitle}
          isSupportIframe={isSupportIframeState}
          providerName={providerName}
        />
      }
      

    </>
  )
}

export default ProviderList;
